@tailwind base;
@tailwind components;
@tailwind utilities;

.buttonAnimation:hover {
	animation: button-hover 1s;
	animation-fill-mode: forwards;
	filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.33));
}

@keyframes button-hover {
	0% {
		color: black;
		background-color: rgba(0, 0, 0, 0);
		border-color: rgba(0, 0, 0, 1);
	}
	8% {
		border-color: rgba(0, 0, 0, 0.25);
	}
	16% {
		border-color: rgba(0, 0, 0, 1);
	}
	24% {
		border-color: rgba(0, 0, 0, 0.25);
	}
	32% {
		border-color: rgba(0, 0, 0, 1);
	}
	40% {
		border-color: rgba(0, 0, 0, 0.25);
	}
	48% {
		color: black;
		background-color: rgba(0, 0, 0, 0);
		border-color: rgba(0, 0, 0, 1);
	}
	100% {
		color: white;
		background-color: rgba(0, 0, 0, 1);
		border-color: rgba(0, 0, 0, 1);
	}
}
